import { ServerConfig } from './config.interface';

const highlandSportConfig: ServerConfig = {
  id: 'highlandsport',
  code: 'havlickuv-brod-highland-sport',
  displayPartnerPages: true,
  cssTheme: 'highlandsport',
  modalTypeToSlugMapping: {
    'gdpr': 'zasady-ochrany-osobnich-udaju',
    'biometric': 'predani-biometrickych-udaju',
    'terms': 'obchodni-podminky'
  },
  data: {
    homepage: {
      static_page_tiles: {}
    },
    product: {
      product_detail_tiles: {
        delivery_methods: {
          title: 'Pickup points',
          link: 'static/pujcovna-highland-sport',
          active: true
        },
        contacts: {
          title: 'Contacts',
          link: '/static/zpusoby-doruceni',
          active: true
        }
      }
    }
  },
  imageToPreload: [],
  faviconList: [
    "<link rel='icon' type='image/x-icon' href='/assets/higland-favicon.ico' sizes='32x32'>"
  ]
};

export default highlandSportConfig;
